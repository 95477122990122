
import image from "../../images/1.jpg"
import About from '../About/About'
import Contact from '../Contact/Contact'
import Gallary from '../Gallary/Gallary'
import FadeIn from 'react-fade-in'



const Home = () => {
    
    return (
        <FadeIn>
        <div className="h-screen flex md:flex-row flex-col justify-center items-center">
            <div className="bg-pro bg-opacity-60 h-screen md:bg-none z-1 mt-16">
                <h1  className="lg:text-9xl md:text-7xl sm:text-5xl text-5xl font-black mb-14 text-center items-center py-12">👋Hi ;-)</h1>
                <p  className=" md:mx-auto mx-7 text-xl  md:w-1/2 text-left items-start  md:px-12">I am <b>Syed Yashin Hussain</b>, a Junior Web Developer 💻, Gamer🎮, Software Developer🎯, and a Student👨‍🎓. I am currently pursuing a Diploma in CSE at Nalbari Polytechnic.  </p>
            </div>
            <div className="w-1/2 mr-8" ><img src={image} alt="ME" className="ring-4 ring-gray-300 z-0 hidden md:block rounded-full"/></div>
            
        </div>
        <About />
        <Contact />
	<Gallary />
    
    </FadeIn>
    )
}

export default Home
