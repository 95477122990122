import React from 'react'
import image from "../../images/this-one.jpg";
import FadeIn from 'react-fade-in'
import {SiGodotengine, SiCplusplus, SiHtml5, SiCss3, SiJavascript, SiPhp, SiPython, SiCsharp, SiBlender, SiLinux, SiAdobephotoshop, SiFigma,  SiJava, SiDotNet, SiFirebase, SiMysql} from 'react-icons/si'
const About = () => {
    return (
        <FadeIn>
        <div className=" bg-opacity-60 bg-about md:bg-none h-fill bg-no-repeat bg-cover md:bg-gray-900 md:bg-opacity-75 md:justify-center md:p-16">
            <img src={image} alt="nothing" className=" md:mt-4 ring-4 ring-gray-300 hidden md:block w-1/5 md:align-center md:rounded-full justify-center m-auto"/>
            <h1 className=" text-center md:text-7xl p-4 text-5xl md:m-auto">About</h1>
            <p className="md:mx-12 mx-7 text-xl  text-center  px-12">
            <br></br><strong>S</strong>ince I was in my seventh standard, computers inspired me. The way that a simple device holds enough power to help Mankind reach Outerspace. <a href="//en.wikipedia.org/wiki/Apollo_11"><u> Apollo 11</u></a> in 1969 made history by sending men to the Moon. And Do you know the computers at that time had only <b>4kb RAM</b>?These questions fascinated me. Hence I choose to join the World of Computer Sciences.</p>
            <h1 className="md:border-b-3 border-white mx-auto w-1/2 md:text-7xl p-4 text-5xl text-center ">Education</h1>
            <p className="md:mx-auto mx-7 text-xl p-6 md:w-1/2 text-center px-12">I am currently pursuing a Diploma in CSE at Nalbari Polytechnic. I have done my Secondary education at Kendriya Vidyalaya Jagiroad. I have done my Primary education at Kendriya Vidyalaya.</p>
            <h1 className="md:border-b-3 border-white mx-auto w-1/2 md:text-7xl p-4 text-5xl text-center ">Area of Intrests</h1>
            <p className="md:mx-auto mx-7 text-xl p-6 md:w-1/2 text-center px-12">My area of interest revolves around:
            <ol className="list-disc text-left">
            <li>Full Stack Development</li>
            <li>Game Development using <a href="https://godotengine.org/"><b className="inline-flex">Godot<SiGodotengine /></b></a></li>
            <li>Software Development using <b className="inline-flex">C++, Java, Python </b></li>
            </ol></p>
            <h1 className="md:border-b-3 border-white mx-auto w-1/2 md:text-7xl p-4 text-5xl text-center">Skills</h1>
            <div className="pb-7 flex md:justify-center mx-12 w-auto" >
            
            <table className="w-full sm:text-sm border-collapse md:border-separate border-4 md:w-1/2 border-green-800 ">
                <tr>
                    <th class="border border-green-600">Skills</th>
                    <th class="border border-green-600">Experience</th>
                </tr>
                <th>Design</th>
                <tr>
                    <td className="flex "><p className="m-2">Figma</p><SiFigma className="h-auto"/></td>
                    <td><p>intermediate 6/10</p></td>
                </tr>
                <tr>
                <td className="flex "><p className="mx-2">Adobe Photoshop</p><SiAdobephotoshop className="h-auto"/></td>
                <td><p>intermediate 8/10</p></td>
            </tr>
                <th>Web Development</th>
                <tr>
                    <td className="flex "><p className="mx-2">HTML5</p><SiHtml5 className="h-auto"/></td>
                    <td><p>intermediate 8/10</p></td>
                </tr>
                <tr>
                    <td className="flex "><p className="mx-2"> CSS3</p><SiCss3 className="h-auto"/></td>
                    <td><p>intermediate 7.5/10</p></td>
                </tr>
                <tr>
                    <td className="flex "><p className="mx-2">Javascript ES6+</p><SiJavascript className="h-auto"/></td>
                    <td><p>intermediate 7/10</p></td>
                </tr>
                <tr>
                    <td className="flex "><p className="mx-2"> PHP 7+</p><SiPhp className="h-auto"/></td>
                    <td ><p>intermediate 8/10</p></td>
                </tr>
                <tr>
                    <td className="flex "><p className="mx-2">Firebase</p><SiFirebase className="h-auto"/></td>
                    <td><p>Beginner 5/10</p></td>
                </tr>
                <tr>
                    <td className="flex "><p className="mx-2">mySQL</p><SiMysql className="h-auto"/></td>
                    <td><p>intermediate 8/10</p></td>
                </tr>
                <th>Game Development</th>
                <tr>
                    <td className="flex "><p className="mx-2"> C#(Unity)</p><SiCsharp className="h-auto"/></td>
                    <td><p>Beginner 5/10</p></td>
                </tr>
                <tr>
                    <td className="flex "><p className="mx-2"> Godot v3.3+</p><SiGodotengine className="h-auto"/></td>
                    <td><p>intermediate 7.5/10</p></td>
                </tr>
                <tr>
                    <td className="flex "><p className="mx-2"> Blender 2.9</p><SiBlender className="h-auto"/></td>
                    <td><p>intermediate 8.5/10</p></td>
                </tr>
               
                <th>Software Development</th>
                <tr>
                    <td className="flex "><p className="mx-2">.NET </p><SiDotNet className="h-auto"/></td>
                    <td>intermediate 6.5/10</td>
                </tr>
                <tr>
                    <td className="flex "><p className="mx-2"> JAVA 8+</p><SiJava className="h-auto"/></td>
                    <td><p>intermediate 6/10</p></td>
                 </tr>
                <tr>
                    <td className="flex "><p className="mx-2"> GNU/Linux</p><SiLinux className="h-auto"/></td>
                    <td><p>Advanced 9/10</p></td>
                </tr>
                <tr>
                    <td className="flex "><p className="mx-2"> C++(2017)</p><SiCplusplus className="h-auto"/></td>
                    <td><p>intermediate 8/10</p></td>
                </tr>
                <tr>
                    <td className="flex "><p className="mx-2"> Python</p><SiPython className="h-auto"/></td>
                    <td><p>intermediate 8/10</p></td>
                </tr>
              

            </table>
            </div>
            </div>
        </FadeIn>
    )
}

export default About
