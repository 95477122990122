import React from 'react'
import FadeIn from 'react-fade-in'
import P1 from './P1'
import P3 from './P3'
import P4 from './P4'
import P2 from './P2'

const Gallary = () => {
    return (
        <FadeIn>
        <div className=" h-fill md:h-screen md:overflow-hidden bg-gray-800 bg-opacity-75 align-center">
            <h1 className="md:mt-8 md:text-6xl text-3xl p-8 text-center">Gallery</h1>
            <div className="grid grid-rows-4 md:grid-cols-4  px-16">
                <P1 />
                <P2 />
                <P3 />
                <P4 />
               </div>
        </div>
        </FadeIn>
    )
}

export default Gallary