import React from 'react'
import {Link} from 'react-router-dom'


const Dropdown = ({isOpen,toggle}) => {
    return (
        <div className={isOpen ? ' transition-all duration-500 ease-in-out grid grid-rows-4 text-center item-center bg-black bg-opacity-90 absolute w-screen z-50' : 'hidden'} onClick={toggle}>
       
          <Link to="/" className=" hover:text-blue-200 p-4">Home</Link>
      
          <Link to="/about" className=" hover:text-blue-200 p-4">About</Link>
       
          <Link to="/contact" className=" hover:text-blue-200 p-4">Contact</Link>
       
        <Link to="/gallary" className="hover:text-blue-200 p-4 ">Gallery</Link>
      
        </div>
    )
}

export default Dropdown
