import React, {useState, useEffect} from 'react'
import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import Footer from "./Footer";
import Home from "./components/Home/Home";
import Gallary from "./components/Gallary/Gallary";
import About from "./components/About/About";
import Contact from "./components/Contact/Contact";
import Dropdown from "./components/Dropdown";

// import "./main.css";
// import "tailwindcss/tailwind.css"

function App() {
  const [isOpen,setisOpen]= useState(false)
  
  const toggle = () => {
    setisOpen (!isOpen)
  }

  useEffect (()=>{
    const hidemenu= ()=>{
      if (window.innerWidth > 768 && isOpen){
        setisOpen(false)
        
      }
    }
    window.addEventListener('resize', hidemenu)
    return ()=>{
      window.removeEventListener('resize', hidemenu)
    }
  })
  return (
   
    <div className=" bg-black bg-no-repeat text-gray-50 mx:auto z-0 ">
    <Router>
    <div>
      <nav className=" bg-black bg-opacity-90 absolute w-screen z-25">
      <div className="md:hidden p-6  cursor-pointer " onClick={toggle}>
      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h8m-8 6h16" />
      </svg>
      </div>
      <Dropdown isOpen={isOpen} toggle={toggle}/>
        <ul className=" text-center p-8 hidden md:flex justify-center items-center h-16 delay-75 ">
          <li>
            <Link to="/" className=" hover:text-blue-200 p-4">Home</Link>
          </li>
          <li>
            <Link to="/about" className=" hover:text-blue-200 p-4">About</Link>
          </li>
          <li>
            <Link to="/contact" className=" hover:text-blue-200 p-4">Contact</Link>
          </li>
          <li>
          <Link to="/gallary" className="hover:text-blue-200 p-4 ">Gallery</Link>
        </li>
        </ul>
      </nav>
      
      <Switch >
        <Route exact path="/">
          <Home  />
        </Route>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/contact">
          <Contact />
        </Route>
        
        <Route path="/gallary">
        <Gallary />
        </Route>
      </Switch>
      
    </div>
    
  </Router>
  
    <Footer/>
    
            
    </div>
  );
}


export default App;
