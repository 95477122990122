import React from 'react'
import FadeIn from 'react-fade-in'
import emailjs from 'emailjs-com'
import {FaPaperPlane} from 'react-icons/fa'

const Contact = () => {

        function sendEmail(e) {
          e.preventDefault();
      
          emailjs.sendForm('service_z6qnj9p', 'template_hnxug3k', e.target, 'user_yKKihcwqM5JV39C3sdy9D')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        }
        const toggle =() =>  {
            alert("Your message have been received");
        }
       
    return (
        <FadeIn>
            <div className="bg-hero-pattern bg-center md:px-16 h-screen px-2 bg-black bg-opacity-40 " >
            <h1 className="contact-form text-center text-4xl py-14">Contact Me:</h1>
                <form className="flex flex-col md:p-32 md:py-px p-1 m-6 " onSubmit={sendEmail} >

                 <input type="text" name="name" required placeholder="Enter your name" className="p-2 m-3  rounded-2xl text-black focus:bg-gray-100" ></input>

                 <input type="email" name="email" required  placeholder="Enter your email" className="p-2 m-3 rounded-2xl text-black focus:bg-gray-100"></input>
                 <input type="text" name="subject" required placeholder="Enter the subject" className="p-4 m-3 rounded-2xl text-black focus:bg-gray-100"></input>
                 <input type="textarea" required placeholder="Enter your message" className="p-6 m-3 rounded-2xl text-black focus:bg-gray-100"></input>
                  <button  className=" flex justify-center p-2 border-collapse bg-blue-800 px-6 py-2 rounded-3xl hover:bg-blue-900 uppercase" type="submit" value="Send" onClick={toggle}><div className="p-1"><FaPaperPlane /></div>submit</button>
                 
                  </form>
                </div>
        </FadeIn>
    )
}

export default Contact
