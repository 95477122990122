import React from 'react'

const P1 = () => {
    
    return (
        <div >
            <a href="https://github.com/Gibberlin/VoiceAssistant">
                <div className="h-64 bg-white md:w-64 w-fill my-6 rounded-xl text-black md:overflow-hidden text-center text-2xl hover:bg-gray-100">
                     <h3 className="font-bold">Voice assistant</h3>
                     <p className="p-5 font-thin">This is a simple Voice Assistant app made with Python3.9.</p>
                        <div className="bg-purple-600 h-3 w-12 rounded-xl mx-auto my-6">
                            <p className="text-sm p-auto pt-10 md:pt-3">Python3</p>
                        </div>
                </div>
            </a>
            
        </div>
    )
}

export default P1