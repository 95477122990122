import React from 'react'
import {FaInstagram } from 'react-icons/fa'
import {VscGithub} from 'react-icons/vsc'
import {SiCodepen, SiWhatsapp} from 'react-icons/si'

const Footer = () => {

    return (
        <div className="text-center text-sm bg-black bg-opacity-75 p-16">
            <p>This website is maintained and operated by <b>Syed Yashin Hussain</b>.
            The information provided here is for personal and educational purpose only</p>
            <p>&copy; 2020-2022 Syed Yashin Hussain v0.0.7</p>
            <h2>Social Media</h2>
            <p>Let's get to know each other</p>
            <div className="flex flex-row justify-center items-center">
            <a href="https://codepen.io/gibberlin"><div className="flex flex-col text-center m-3 h-32"><SiCodepen className="fill-current text-grey-300 text-4xl"/></div></a>
            <a href="https://Github.com/Gibberlin"><div className="flex flex-col text-center m-3 h-32"><VscGithub className="fill-current text-purple-600 text-4xl"  /></div></a>
            <a href="https://wa.me/919101051183"><div className="flex flex-col text-center m-3 h-32"><SiWhatsapp className="fill-current text-green-600 text-4xl"/></div></a>
            <a href="https://www.instagram.com/https_yashin_/"><div className="flex flex-col text-center m-3 h-32"><FaInstagram className="fill-current text-pink-600 text-4xl"/></div></a>
            </div>
        </div>

    )
}

export default Footer
