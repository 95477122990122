import React from 'react'

const P3 = () => {
    return (
        <div>
        <a href="https://github.com/Gibberlin/box-office">
        <div className="h-64 bg-white md:w-64 w-fill my-6 rounded-xl text-black md:overflow-hidden text-center text-2xl hover:bg-gray-100 ">
             <h3 className="font-bold p1">Box office</h3>
             <p className="p-5 font-thin">A Movies reviewer app made with tvamaze api</p>
                <div className="flex flex-col">
                <div className="bg-blue-400 h-3 w-12 rounded-xl mx-auto my-6">
                <p className="text-sm p-auto pt-10 md:pt-3">React</p>
            </div>
         </div>       
        </div>
            </a>
        </div>
    )
}

export default P3